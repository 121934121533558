<template>
  <v-row class="pa-0">
    <v-col cols="12" class="d-flex justify-space-between">
      <div style="width:100%;min-height:46vh;">
        <v-tabs v-model="tabs" fixed-tabs class="d-flex justify-start theme--dark"
          style="background-color:rgb(48, 48, 48);" @change="tabClicked">
          <v-tabs-slider></v-tabs-slider>
          <v-tab class="text-no-wrap px-8" style="background-color: #303030; color:white;">
            <v-icon class="mr-1">mdi-bullseye-arrow</v-icon><span class="mx-auto">MEDIA PLAN</span>
          </v-tab>

          <v-tab class="text-no-wrap px-8" style="background-color: #303030; color:white;">
            <v-icon class="mr-1">mdi-sitemap-outline</v-icon><span class="mx-auto">SITE LIST</span>
          </v-tab>

          <v-tab class="text-no-wrap px-8" style="background-color: #303030; color:white;">
            <v-icon class="mr-1">mdi-handshake</v-icon><span class="mx-auto">TRADING SHEET</span>
          </v-tab>

          <v-tab class="text-no-wrap px-8" style="background-color: #303030; color:white;">
            <v-icon class="mr-1">mdi-seal-variant</v-icon><span class="mx-auto">PCA</span>
          </v-tab>

          <v-tab v-show="$store.state.Permissions.campaignEdit" class="text-no-wrap px-8"
            style="background-color: #303030; color:white;">
            <v-icon class="mr-1">mdi-format-size</v-icon><span class="mx-auto">FONTS</span>
          </v-tab>

          <v-tab class="text-no-wrap px-8" style="background-color: #303030; color:white;">
            <v-icon class="mr-1">mdi-variable</v-icon><span class="mx-auto">MISC</span>
          </v-tab>
        </v-tabs>
        <v-row class="align-center mt-4">
          <!--
          <v-icon large class="mr-2 mb-1">mdi-format-font</v-icon>
          <h1 style="font-weight: 400;">{{ (campaignAssets.length > 0) ? 'Assets Added' : 'Add Assets' }}</h1>
          -->
          <!-- drag & drop upload -->
          <!-- Grid view --><!--
          <v-col cols="auto" class="ma-0 px-1 ml-10">
            <v-icon color="white" :style="{ fontSize: `${30}px` }">mdi-view-grid-outline</v-icon>
          </v-col>-->
          <!-- List view --><!--
          <v-col cols="auto" class="ma-0 px-0">
            <v-icon color="grey" :style="{ fontSize: `${38}px` }">mdi-view-list-outline</v-icon>
          </v-col>-->
          <v-col class="d-flex justify-end col col-12">
            <div v-if="$store.state.Permissions.libraryEdit" @click="$refs.fontsUpload.click()" @dragover.prevent
              @drop.prevent style="width: 150px;" class="mx-10">
              <input @click="$refs.fontsUpload.value = ''" ref="fontsUpload" type="file" multiple hidden
                @change="uploadFont" />
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-hover v-slot="{ hover }">
                    <div ref="dragDiv" v-bind="attrs" v-on="on" @drop="dragFile"
                      @dragleave="$refs.dragDiv.style.backgroundColor = 'transparent'"
                      @dragover="$refs.dragDiv.style.backgroundColor = 'grey'" style="
                      background-color: transparent;
                      padding: 0.66em 0em;
                      transition: 0.1s;
                    " :style="hover ? { border: 'solid 1px rgba(255,255,255, .8)' } : { border: 'solid 1px rgba(255,255,255, .3)' }"
                      class="rounded">
                      <div style="width:100%; text-align:center;">
                        <v-icon large>
                          mdi-cloud-upload
                        </v-icon>
                      </div>
                    </div>
                  </v-hover>
                </template>
                <span>Upload Files</span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
        <!-- Asset Cards -->
          <v-row class="px-1 mx-0">
            <v-col v-for="(asset, index) in tabFilteredCards" :key="index" cols="auto" class="ma-0 px-4">
              <v-card elevation="2" class="d-flex flex-column ma-0 pa-0" width="240"
                style="font-size:10px;background-color: #303030;">
                <v-card-title class="primary white--text ma-0 py-0 px-2" style="height: 3rem;">
                  <v-row class="px-3">
                    <!--
                    <v-col cols="2" class="ma-0 pa-0 d-flex align-center justify-center" v-if="tabs == 0">
                      <!--
                      <v-checkbox v-if="tabs == 4" color="white" class="pa-0 mx-0" input-value="true" />
                      <v-radio v-if="tabs == 0" color="white" class="pa-0 mx-0" :value="asset.id"></v-radio>
                      ->
                      <v-checkbox color="white" class="pa-0 mx-0" v-model="asset.promoted" @change="clickPromated(asset)"/>
                    </v-col>
                    -->
                    <v-col :cols="10" class="ma-0 pa-0 d-flex align-center justify-center">
                      <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                        style="width: 100%">
                        <p class="ma-0 pa-0 px-1" style="font-size: 11px;line-height: 1em;">
                          {{asset.media.originalFileName.length > 30 ? "[...] "+asset.media.originalFileName.slice(-25) : asset.media.originalFileName}}
                        </p>
                      </div>
                      </template>
                        <span>{{ asset.media.originalFileName }}</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="1" class="ma-0 pa-0 pr-2 d-flex align-center justify-center">
                      <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              color="white"
                              icon
                              small
                              @click.prevent="downloadFile(asset)"
                            >
                              <v-icon>mdi-download-circle</v-icon>
                            </v-btn>
                          </template>
                          <span>Download Asset</span>
                        </v-tooltip>
                        <!--
                      <div>
                        <div class="pa-0 mx-0">
                          <a :href="asset.media.url" @click.prevent="downloadFile(asset)">
                            <v-icon icon
                            small style="font-size: 26px;">mdi-download-circle</v-icon>
                          </a>
                        </div>
                      </div>
                      -->
                    </v-col>
                    <v-col cols="1" class="ma-0 pa-0 d-flex align-center justify-center">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              color="white"
                              icon
                              small
                              @click="openAssetEdit(asset)"
                              :disabled="contactCard.email !== asset.uploadedBy && !$store.state.Permissions.libraryEditAnyItem"
                            >
                              <v-icon>mdi-cog</v-icon>
                            </v-btn>
                          </template>
                          <span>Edit Asset</span>
                        </v-tooltip>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-text flex-grow-1 style="min-height:90px">
                  <v-row style="width:100%;font-size:12px;color:white;"
                    class="mt-3 mb-2 mx-auto text-no-wrap text-center justify-center">
                    Uploaded: {{ new Date(asset.dateCreated).toLocaleString("en-GB", {
                    day: "2-digit", month: "long", year: "numeric"
                    }) + " " + new Date(asset.dateCreated).toLocaleTimeString("en-GB")
                    }}
                    
                  </v-row>
                  <v-row v-if="isFont(asset.media.originalFileName)">
                  <!--                        fontFamily: `${asset.media.originalFileName.split('.').slice(0, -1).join('.').replaceAll(' ','_')}WOFF`,
-->
                    <div class="text--primary text-center px-2" :style="{ 
                        fontFamily: asset.media.originalFileName.split('.').slice(0, -1).join('.').replaceAll(' ', '_') +
                                    asset.media.originalFileName.split('.').pop().toUpperCase(),
                        fontSize: '20px',
                        lineHeight: '1.2em' 
                        }">
                      <p>{{ fontText[asset.index] }}</p>
                    </div>
                  </v-row>
                  <v-row style="width:100%;font-size:12px;line-height:1em;color:white;"
                    class="mt-3 mx-auto text-center justify-center align-center">
                    {{ asset.description ? asset.description : "no comments" }}
                  </v-row>
                </v-card-text>

                <v-card-actions v-if="tabs == 4" class="d-flex flex-column pa-0 ma-0 mt-2">
                  <v-layout justify-center  :height="30">
                    <v-btn
                      v-if="!campaignAssets.map((x) => x.media.originalFileName).includes(asset.media.originalFileName.replace(/\.(ttf|otf)$/i, '.woff'))"
                      text color="primary" @click="getWoffFromGenerator(asset)"
                      class="text-center justify-center align-center mx-auto pa-0 my-0 d-flex"
                      :height="30">
                      Convert to Woff
                    </v-btn>
                    <div v-else-if="asset.media.originalFileName.toLowerCase().endsWith('.otf') || asset.media.originalFileName.toLowerCase().endsWith('.ttf')"
                      text class="text-center justify-center align-center mx-auto pa-0 my-0 d-flex" style="color:grey;height:30px">
                      Already Converted
                    </div>
                    <div v-if="asset.media.originalFileName.toLowerCase().endsWith('.woff')"
                      class="text-center justify-center align-center mx-auto pa-0 my-0 d-flex" style="color:grey;height:30px">
                      USED IN {{ asset.usageCount }} ASSETS
                    </div>
                  </v-layout>
                </v-card-actions>

                <v-card-actions v-if="tabs == 0" class="d-flex flex-column pa-0 ma-0 mt-2">
                  <v-layout justify-center  :height="30">
                    <v-btn
                      v-if="!asset.promoted"
                      text color="primary" @click="clickPromated(asset)"
                      class="text-center justify-center align-center mx-auto pa-0 my-0 d-flex"
                      :height="30">
                        Promote Media Plan
                    </v-btn>
                    <v-btn v-else @click="clickPromated(asset)" text class="primary text-center justify-center align-center mx-auto pa-0 my-0 d-flex" style="height:30px">
                        Promoted
                    </v-btn>
                  </v-layout>
                </v-card-actions>

                <v-card-actions 
                  :height="30" class="pt-1">
                  <!--
                <v-layout justify-center v-if="tabs == 4">
              <v-row  :style="{height: '5rem'}" class="text-center justify-center align-center mx-auto">
                  <v-btn
                    v-if="!campaignAssets.map((x)=>x.media.originalFileName).includes(asset.media.originalFileName.replace(/\.(ttf|otf)$/i,'.woff'))"
                    width="100%" text color="primary" @click="getWoffFromGenerator(asset)"
                    class="text-center justify-center align-center mx-auto">
                    Convert to Woff
                  </v-btn>
                  <div v-else width="100%" text color="primary" class="text-center justify-center align-center mx-auto">
                    Already Converted
                  </div>
                </v-layout>-->
                  <!--
                <v-row v-if="tabs == 4" class="w-100">
                  <v-btn
                    v-if="!campaignAssets.map((x)=>x.media.originalFileName).includes(asset.media.originalFileName.replace(/\.(ttf|otf)$/i,'.woff'))"
                    width="80%" text color="primary" @click="getWoffFromGenerator(asset)">
                    Convert to Woff
                  </v-btn>
                  <div v-else width="80%" text color="primary">
                    Already Converted
                  </div>
                </v-row>-->
                  <v-tooltip content-class="top" top>
                    <template v-slot:activator="{ attrs, on }">
                      <v-btn v-bind="attrs" v-on="on" icon color="red" @click="selectedAsset = asset; showRemoveModal = true;"
                        :disabled="contactCard.email !== asset.uploadedBy && !$store.state.Permissions.libraryEditAnyItem"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>

                  <div class="text-center justify-center align-center mx-auto" style="color:grey;width:90%">
                    {{ asset.uploadedBy }}
                  </div>

                  <!-- Document Icon  -->
                  <v-btn icon color="primary">
                    <v-icon :color="getIconByFilename(asset.media.originalFileName).color">{{ getIconByFilename(asset.media.originalFileName).icon }}</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>

            </v-col>
          </v-row>
        <!-- Woff Fonts -->
        <!--
        <v-row
          v-if="$store.state.Permissions.campaignEdit && campaignAssets.filter((x)=>x.media.originalFileName.split('.').pop() == 'woff').length > 0">
          <h2 class="text-h2 text--primary"><br>Fonts</h2>
        </v-row>
        <v-row v-if="$store.state.Permissions.campaignEdit" class="d-flex justify-start flex-start align-start"
          style="column-gap: 1.5em;">
          <v-card max-width="344" min-width="344" min-height="270px" height="auto"
            v-for="(asset, index) in campaignAssets.filter((x)=>x.media.originalFileName.split('.').pop() == 'woff')"
            :key="index" outlined>
            <v-card-text>
              <div class="text--primary">{{ asset.media.originalFileName }}</div>
              <p class="text-h4">
                Used in {{ asset.usageCount }} assets.<br />
                Created on: {{ asset.dateCreated }}<br />
                <span v-if="asset.uploadedBy">Created by: {{ asset.uploadedBy }}</span><br />
              </p>
              <div class="text--primary" :style="{ 
                  fontFamily: `${asset.media.originalFileName.split('.').slice(0, -1).join('.').replaceAll(' ','_')}WOFF`,
                  fontSize: '20px',
                  lineHeight: '1.2em' 
                  }">
                <p>{{ fontText[asset.index] }}</p>
              </div>
            </v-card-text>
          </v-card>
        </v-row>
        -->
        <!-- OTF / TTF Fonts -->
        <!--
        <v-row
          v-if="$store.state.Permissions.campaignEdit && campaignAssets.filter((x)=>['ttf','otf'].includes(x.media.originalFileName.split('.').pop())).length > 0">
          <h2 style="font-weight: 400;"><br>TTF / OTF Fonts</h2>
        </v-row>
        <v-row v-if="$store.state.Permissions.campaignEdit" class="d-flex justify-start flex-start align-start"
          style="column-gap: 1.5em;">
          <v-card max-width="344" min-width="344" min-height="270px" height="auto"
            v-for="(asset, index) in campaignAssets.filter((x)=>['ttf','otf'].includes(x.media.originalFileName.split('.').pop()))"
            :key="index" outlined>
            <v-card-text>
              <div>{{ asset.media.originalFileName }}</div>
              <p>
                Created on: {{ asset.dateCreated }}<br />
                <span v-if="asset.uploadedBy">Created by: {{ asset.uploadedBy }}</span><br />
              </p>
              <div class="text--primary mb-4" :style="{ 
                  fontFamily: `${asset.media.originalFileName.replace(/\.([a-z]+)$/i, (_, ext) => `.${ext.toUpperCase()}`).replaceAll('.','').replaceAll(' ','_')}`,
                  fontSize: '20px',
                  lineHeight: '1em',
                  minHeight: '80px'
                  }">
                <p>{{ fontText[asset.index] }}</p>
              </div>
            </v-card-text>
            <v-card-actions class="pt-0" style="justify-content: center">
              <v-btn
                v-if="!campaignAssets.map((x)=>x.media.originalFileName).includes(asset.media.originalFileName.replace(/\.(ttf|otf)$/i,'.woff'))"
                width="80%" text color="primary" @click="getWoffFromGenerator(asset)">
                Convert to Woff
              </v-btn>
              <div v-else width="80%" text color="primary">
                Already Converted
              </div>
            </v-card-actions>
          </v-card>
        </v-row>
        -->
        <!-- DOCUMENTS: xlsx, ppt, word, pdf, txt-->
        <!--
        <v-row v-if="campaignAssets.filter((x)=>isDocument(x.media.originalFileName)).length > 0">
          <h2 style="font-weight: 400;"><br>Documents</h2>
        </v-row>
        <v-row class="d-flex justify-start flex-start align-start" style="column-gap: 1.5em;">
          <v-card max-width="344" min-width="344" height="auto" style="text-align: center;" class="pa-2"
            v-for="(asset, index) in campaignAssets.filter((x)=>isDocument(x.media.originalFileName))" :key="index"
            outlined>
            <v-card-text style="width: 100%;min-height:140px;" class="ma-1">
              <div style="min-height:50px;">
                <span style="">
                  <i v-if="asset.media.originalFileName.split('.').pop() == 'xls' || asset.media.originalFileName.split('.').pop() == 'xlsx'"
                    data-v-483f777a="" aria-hidden="true"
                    class="v-icon notranslate mr-1 mdi mdi-microsoft-excel theme--dark"></i>
                  <i v-if="asset.media.originalFileName.split('.').pop() == 'doc' || asset.media.originalFileName.split('.').pop() == 'docx'"
                    data-v-483f777a="" aria-hidden="true"
                    class="v-icon notranslate mr-1 mdi mdi-microsoft-word theme--dark"></i>
                  <i v-if="asset.media.originalFileName.split('.').pop() == 'pptx'" data-v-483f777a=""
                    aria-hidden="true" class="v-icon notranslate mr-1 mdi mdi-microsoft-powerpoint theme--dark"></i>
                  <i v-if="asset.media.originalFileName.split('.').pop() == 'pdf'" data-v-483f777a="" aria-hidden="true"
                    class="v-icon notranslate mr-1 mdi mdi-file-pdf-box theme--dark"></i>
                  <i v-if="asset.media.originalFileName.split('.').pop() == 'csv'" data-v-483f777a="" aria-hidden="true"
                    class="v-icon notranslate mr-1 mdi mdi-file-delimited theme--dark"></i>
                  <i v-if="asset.media.originalFileName.split('.').pop() == 'txt'" data-v-483f777a="" aria-hidden="true"
                    class="v-icon notranslate mr-1 mdi mdi-text-box theme--dark"></i>
                </span>
                <a :href="asset.media.url" target="_blank">{{ asset.media.originalFileName }}</a>
              </div>
              <p class="pa-0 ma-0">
                Created on: {{ asset.dateCreated }}<br />
                <span v-if="asset.uploadedBy">Created by: {{ asset.uploadedBy }}<br /></span>
                File size: {{ formatFileSize(asset.media.fileSize) }}
              </p>
            </v-card-text>
          </v-card>
        </v-row>
        -->
        <v-row style="height: 8vh;"></v-row>
      </div>
    </v-col>
    <!-- Update Asset Dialog -->
    <v-dialog v-model="showRemoveModal" @click:outside="showRemoveModal = false;" width="480">
      <v-card v-if="selectedAsset" style="overflow: hidden;" class="ma-0">
        <v-card-title class="ma-0 pa-4 white--text primary" style="font-size: 18px;">
          Update Asset
        </v-card-title>
        <v-card-text class="pa-6 pb-5 text-center">
          Are you sure you want to delete {{ selectedAsset.media.originalFileName }}?
        </v-card-text>
        <v-card-actions class="justify-space-around pb-6">
          <v-btn @click="removeAsset(); showRemoveModal = false;" color="primary" class="mr-2">Okay</v-btn>
          <v-btn @click="showRemoveModal = false;" color="red">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showAssetUpdateModal" @click:outside="showAssetUpdateModal = false; getAssets();"
      width="480">
      <v-card v-if="selectedAsset" style="overflow: hidden;" class="ma-0">
        <v-card-title class="ma-0 pa-4 white--text primary" style="font-size: 18px;">
          Update Asset
        </v-card-title>
        <v-card-text class="pa-6 pb-5">
          <v-row align="center">
            <v-col cols="12" class="text-center pa-0">
              <p class="ma-0 pt-1 pb-4 text-no-wrap align-center d-flex ml-11">
                <v-icon class="pr-3 text-h2" left :color="getIconByFilename(selectedAsset.media.originalFileName).color">{{
                  getIconByFilename(selectedAsset.media.originalFileName).icon
                  }}</v-icon>
                <span style="color:white;">{{ selectedAsset.media.originalFileName }}</span>
              </p>

              <v-select style="width:80%;left:10%" label="Document Type" outlined
                :items="documentTypes.map((x)=>x.documentType)"
                v-model="selectedAsset.documentType.documentType"
              ></v-select>

              <v-textarea style="width:80%;left:10%" label="Comment" outlined no-resize
                v-model="selectedAsset.description">
              </v-textarea>

              <div class="pa-2">
                <div class="d-flex justify-space-between mt-4 px-11">
                  <v-btn @click="showAssetUpdateModal = false; getAssets();" color="red">Cancel</v-btn>
                  <v-btn @click="uploading = true; updateAsset();" color="primary" class="mr-2">Save</v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showFileUploadModal" @click:outside="filesForUpload = null; showFileUploadModal = false;"
      width="480">
      <v-card v-if="filesForUpload && filesForUpload.length > 0" style="overflow: hidden;" class="ma-0">

        <v-card-title class="ma-0 pa-4 white--text primary" style="font-size: 18px;">
          Upload Media {{ filesForUpload ? ""+(uploadMediaDialogSelectedFileIndex+1)+"/" + filesForUpload.length : "" }}
        </v-card-title>

        <!-- Left side button -->
        <button block class="ma-0 pa-0" style="position:absolute;top:60px;height:calc(100% - 60px);width:30px;left:0px"
          :disabled="uploadMediaDialogSelectedFileIndex == 0"
          :style="uploadMediaDialogSelectedFileIndex == 0 ? 'background-color: #222; color: #333;' : 'background-color: #333; color: #666;'"
          @click="decreaseIndex()">
          <v-icon style="font-size:50px; width:100%"
            :style="uploadMediaDialogSelectedFileIndex == 0 ? 'color: #333;' : 'color: #AAA;'">mdi-menu-left</v-icon>
        </button>

        <!-- Right side button -->
        <button block class="ma-0 pa-0" style="position:absolute;top:60px;height:calc(100% - 60px);width:30px;right:0px"
          :disabled="uploadMediaDialogSelectedFileIndex == filesForUpload.length - 1"
          :style="uploadMediaDialogSelectedFileIndex == filesForUpload.length - 1 ? 'background-color: #222; color: #333;' : 'background-color: #333; color: #666;'"
          @click="increaseIndex()">
          <v-icon style="font-size:50px; width:100%"
            :style="uploadMediaDialogSelectedFileIndex == filesForUpload.length - 1 ? 'color: #333;' : 'color: #AAA;'">mdi-menu-right</v-icon>
        </button>

        <v-card-text class="pa-6 pb-5">
          <!--<v-container class="ma-0 pa-0">-->
          <v-row align="center">
            <v-col cols="12" class="text-center pa-0">

              <p class="ma-0 pt-1 pb-4 text-no-wrap align-center d-flex ml-11">
                <v-icon class="pr-3 text-h2" left :color="getIconByFilename(filesForUpload[uploadMediaDialogSelectedFileIndex].name).color">{{
                  getIconByFilename(filesForUpload[uploadMediaDialogSelectedFileIndex].name).icon
                  }}</v-icon>
                <span style="color:white;">{{ filesForUpload[uploadMediaDialogSelectedFileIndex].name }}</span>
              </p>

              <v-select style="width:80%;left:10%" label="Document Type" outlined
                :items="documentTypes.map((x)=>x.documentType)"
                v-model="uploadMediaDialoagAssetInput[uploadMediaDialogSelectedFileIndex].documentType"
              ></v-select>

              <v-textarea style="width:80%;left:10%" label="Comment" outlined no-resize
                v-model="uploadMediaDialoagAssetInput[uploadMediaDialogSelectedFileIndex].comment"></v-textarea>

              <div class="pa-2">
                <div class="d-flex justify-space-between mt-4 px-11">
                  <v-btn @click="filesForUpload = null; showFileUploadModal = false;" color="red">Cancel</v-btn>
                  <v-btn @click="uploading = true; uploadAssets();" color="primary" class="mr-2" :disabled="uploading"
                    :loading="uploading">Save</v-btn>
                </div>
              </div>

            </v-col>
          </v-row>
          <!--</v-container>-->
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Shows warning message when the files aren't allowed format -->
    <v-dialog v-model="showWarningMessage" @click:outside="showWarningMessage = false;" width="500">
      <v-card>
        <v-card-title class="pa-4 white--text primary">
          Invalid File Formats
        </v-card-title>

        <div class="pa-2">
          <ul>
            <p style="text-align: center;">
              Warning some files have unsupported formats or already exist!
            </p>
            <li v-for="(file, index) in filesForUpload" :key="index" class="ml-4">
              <span class="text-h5"
                :style="!isValidFileType(file.name) || campaignAssets.filter((x)=>x.media.originalFileName).includes(file.name) ? { backgroundColor: 'red', color: 'white' } : {}">{{
                file.name }}</span>
            </li>
          </ul>

          <div class="d-flex justify-end mt-4">
            <v-btn @click="showWarningMessage = false;" color="red">Cancel</v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <!-- Show convert ttf to woff message -->
    <v-dialog v-model="showFontConvertionModal" @click:outside="showFontConvertionModal = false;" width="500">
      <v-card>
        <v-card-title class="pa-4 white--text primary">
          Convert TTF
        </v-card-title>

        <div class="pa-2">
          <ul>
            <p style="text-align: center;">
              TTF is not a supported font type. Do you want to convert the ttf files?
            </p>
            <li v-for="(file, index) in filesForUpload" :key="index" class="ml-4">
              <span class="text-h5"
                :style="file.name.split('.').pop() == 'ttf' ? { backgroundColor: 'red', color: 'white' } : {}">{{
                file.name }}</span>
            </li>
          </ul>

          <div class="d-flex justify-end mt-4">
            <v-btn @click="convertFonts(); showFontConvertionModal = false;" color="primary" class="mr-2">Okay</v-btn>
            <v-btn @click="showFontConvertionModal = false;" color="red">Cancel</v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>
  
<script>
  // Services
  import CampaignController from '@/services/controllers/Campaign'
  import UserController from "@/services/controllers/User";
  import axios from 'axios'
  import store from "@/store";

  export default {
    props: {
      campaign: {
        type: Object,
        required: true,
      },
    },

    data () {
      return {
        localCampaign: null,
        campaignAssets: [],
        filesForUpload: [],
        showFileUploadModal: false,
        showWarningMessage: false,
        showFontConvertionModal: false,
        revealedCards: [],
        fontText: [],
        uploading: false,
        tabs: null,
        tabFilteredCards: [],
        uploadMediaDialoagAssetInput: [],
        uploadMediaDialogSelectedFileIndex: 0,
        documentTypes: [],
        tabLookupDocumentTypeId: [],
        showAssetUpdateModal: false,
        showRemoveModal: false,
        selectedAsset: null,
        contactCard: null,
      }
    },
    
    created () {
      // Make local copy
      this.localCampaign = JSON.parse(JSON.stringify(this.campaign))

      this.getAssets();
      UserController.whoAmI().then((res) => {
        this.contactCard = res.data.contactCard;
        console.log("contactCard",this.contactCard);
      });
      this.getDocumentTypes();
    },

    methods: {
      // Cards reveal aniamtion
      revealCard(index) {
        // Set the 'reveal' status for the card at the given index to true.
        this.revealedCards.push({ index, reveal: true });
      },
      hideCard(index) {
        // Find the index of the card with the given index in the 'revealedCards' array and set its 'reveal' status to false.
        const cardIndex = this.revealedCards.findIndex((card) => card.index === index);
        if (cardIndex !== -1) {
          this.revealedCards.splice(cardIndex, 1);
        }
      },
      isCardRevealed(index) {
        // Check if the card at the given index is revealed based on the 'revealedCards' array.
        return this.revealedCards.some((card) => card.index === index && card.reveal);
      },
      // end card reveal animation
      async getAssets() {
        const { data = null } = await CampaignController.getAssets(this.campaign.id)
        .catch(error => { this.$root.$emit("snackbarError", error.response.data.message) })

        if(data == null) return this.campaignAssets = [];

        // Load the fonts to see them
        this.fontText = new Array(data.length).fill("Loading...");
        for(let i = 0; i < data.length;i++) {
          // Get around the filtering and have tha asset know it's own index for the fontText to work
          data[i].index = i;
          var fontFamily = data[i].media.originalFileName.split('.').slice(0, -1).join('.').replaceAll(" ","_");
          var fontExtention = data[i].media.originalFileName.split('.').pop().toUpperCase();
          if( data[i]?.media?.mimeType == "application/font-woff" || 
              data[i]?.media?.mimeType == "application/x-font-ttf" ||
              data[i]?.media?.mimeType == "font/otf") {
            fontFamily += fontExtention;
//            console.log("fontFamily",fontFamily);
// TODO: Check if this needs to be updated to the restricted endpoint to work
          const res = await CampaignController.downloadMedia(data[i].media.id);
          console.log("res",res);
          if(res.status != 200) {
            console.log("getAssets ERROR: index",i,err); 
            this.$set(this.fontText, i, "Error the font failed to load.");
          } else {
            const fontData = await res.data.arrayBuffer();
            document.fonts.add(new FontFace(fontFamily, fontData));
            (new FontFace(fontFamily,fontData)).load().then(()=>{
                this.$set(this.fontText, i, 
"The quick brown fox jumps over the lazy dog. The five boxing wizards jump quickly. Glib jocks quiz nymph to vex dwarf.");
                }).catch((err)=>{
                  console.log("getAssets ERROR: index",i,err);
                  this.$set(this.fontText, i, "Error the font failed to load.");
              });
            }
          }
        }
        this.campaignAssets = [...data];
        this.filterByTab();
      },
      async getDocumentTypes() {
        var res = await CampaignController.getAssetDocumentTypes();
        this.documentTypes = res.data;
        console.log("this.documentTypes",this.documentTypes);
        var tabToDocumentTypes = ["Media Plans","Site Lists","Trading Sheets","PCAs","Fonts","Miscellaneous"];
        this.tabLookupDocumentTypeId = tabToDocumentTypes.map((x)=>this.documentTypes.filter(y=>y.documentType == x)[0].id);
        console.log("tabLookupDocumentTypeId",this.tabLookupDocumentTypeId);
      },

      async uploadAssets() {
        console.log("this.uploadMediaDialoagAssetInput",this.uploadMediaDialoagAssetInput);
        this.uplaoding = true;
        console.log("this.filesForUpload",this.filesForUpload);
        for (let index = 0; index < this.filesForUpload.length; index++) {
          const file = this.filesForUpload[index];
          if(this.campaignAssets.map((x)=> x.media ? x.media.originalFileName : "").includes(file.name)) {
            console.log("File already exists! "+file.name);
            this.$root.$emit('snackbarError', "File with the same name is already exists "+file.name);
          } else {
            const formData = new FormData()
            formData.append('file', file)
            formData.append('campaignId', this.campaign.id)
            formData.append('feature', "restricted");

            const mediaRes = await CampaignController.uploadMedia(formData).catch(error => { 
              this.$root.$emit("snackbarError", error.response.data.message) 
            })
            var documentTypeId = this.documentTypes.filter(x => 
              this.uploadMediaDialoagAssetInput[index].documentType == x.documentType)[0].id;
            await CampaignController.addAsset(
              { campaignId: this.campaign.id, mediaId: mediaRes.data.id, 
                documentTypeId: documentTypeId, 
                description: this.uploadMediaDialoagAssetInput[index].comment})
            .catch(error => { this.$root.$emit("snackbarError", error.response.data.message) })
          }
        }

        this.filesForUpload = null
        this.showFileUploadModal = false

        this.getAssets()
        this.uploading = false;
      },
      async getWoffFromGenerator(asset) {
        console.log("getWoffFromGenerator ",asset);
        // Don't convert fonts that have a file with the name that would already exist
        if(this.campaignAssets.map((x)=> x.media.originalFileName).includes(asset.media.originalFileName.replace(/\.(ttf|otf)$/i,".woff"))) {
          console.log("Woff file already exists not doing font conversion");
          this.$root.$emit('snackbarError', "File with the same name is already exists. Can not convert "+asset.media.originalFileName);
          return;
        }
        axios.get(process.env.VUE_APP_GENERATOR_URL + 
          'generateWoff?originalFileName=' + asset.media.originalFileName +
          '&mediaId='+asset.media.id
          , {
            responseType: 'arraybuffer',
            headers: { 'Content-Type': 'multipart/form-data' }
          }).then(async (res) => {
            console.log("response from generator");
            let fileName = asset.media.originalFileName.replace(/\.(ttf|otf)$/i, '.woff');
            const file = new File([res.data], fileName, { type: 'application/font-woff' })
            
            try {
              const formData = new FormData()
              formData.append('file', file)
              formData.append('campaignId', this.campaign.id)
              formData.append('feature', "restricted");

              const mediaRes = await CampaignController.uploadMedia(formData).catch(error => { 
                this.$root.$emit("snackbarError", error.response.data.message) 
              })

              console.log("mediaRes",mediaRes);
              console.log("this.campaign.id",this.campaign.id);

              await CampaignController.addAsset({ 
                campaignId: this.campaign.id, 
                mediaId: mediaRes.data.id, 
                documentTypeId: this.documentTypes.filter(x=>x.documentType == "Fonts")[0].id, 
                description: asset.description ? asset.description : ""
              })
              .catch(error => { this.$root.$emit("snackbarError", error.response.data.message) })
              this.getAssets();
            } catch(err) {
              console.log(err);
            }

            return file;
        })
        .catch((err) => {
          console.log("Error: ",err);
          this.$root.$emit('snackbarError', 'Failed connecting to Generate woff from ttf');
        })
        return null;
      },
      uploadFont(e) {
        var files = Object.values(e.target.files);
        console.log("uploadFont > files",files);

        this.filesForUpload = files;

        // Remove files that have the same file name to upload
        var assetFilenames = this.campaignAssets.map((x)=> x.media ? x.media.originalFileName : "");
        if(files.filter((x) => assetFilenames.includes(x.name)).length > 0) {
          var duplicateFiles = files.filter((x) => assetFilenames.includes(x.name));
          console.log("duplicateFiles: "+duplicateFiles.map((x)=>x.name).join(", "));
          this.filesForUpload = files.filter((x) => !assetFilenames.includes(x.name));
          if(files.length == 0) {
            this.$root.$emit('snackbarError', "File(s) with the same name is already exists. No other files to upload");
            return;
          } else {
            this.$root.$emit('snackbarError', "File(s) with the same name is already exists. These file(s) were not added: "+duplicateFiles.map((x)=>x.name).join(", "));
          }
        }

        if(files.filter((x)=>!this.isValidFileType(x.name)).length > 0) {
          console.log("Not allowed file format OR file already exists");
          this.showWarningMessage = true;
          return;
        }

        this.uploading = false;
        if(this.tabs == null) this.tabs = 0;
        this.uploadMediaDialoagAssetInput = files.map(()=>{return {
          comment:"",
          documentType: this.documentTypes.filter(x=>this.tabToDocumentTypeId(this.tabs) == x.id)[0].documentType
        }});
        console.log("this.uploadMediaDialoagAssetInput",this.uploadMediaDialoagAssetInput);
        this.uploadMediaDialogSelectedFileIndex = 0;
        this.showFileUploadModal = true
      },

      // Purpose: Execute after files are dragged & dropped from the explorer
      dragFile (e) {
        const files = Object.values(e.dataTransfer.files)
        console.log("dragFile > files",files);

        this.filesForUpload = files;

        // Remove files that have the same file name to upload
        var assetFilenames = this.campaignAssets.map((x)=> x.media ? x.media.originalFileName : "");
        if(files.filter((x) => assetFilenames.includes(x.name)).length > 0) {
          var duplicateFiles = files.filter((x) => assetFilenames.includes(x.name));
          console.log("duplicateFiles: "+duplicateFiles.map((x)=>x.name).join(", "));
          this.filesForUpload = files.filter((x) => !assetFilenames.includes(x.name));
          if(files.length == 0) {
            this.$root.$emit('snackbarError', "File(s) with the same name is already exists. No other files to upload");
            return;
          } else {
            this.$root.$emit('snackbarError', "File(s) with the same name is already exists. These file(s) were not added: "+duplicateFiles.map((x)=>x.name).join(", "));
          }
        }
        if(files.filter((x)=>!this.isValidFileType(x.name)).length > 0) {
          console.log("Not allowed file format");
          this.showWarningMessage = true;
          return;
        }

        this.uploading = false;
        if(this.tabs == null) this.tabs = 0;
        this.uploadMediaDialoagAssetInput = files.map(()=>{return {
          comment:"",
          documentType: this.documentTypes.filter(x=>this.tabToDocumentTypeId(this.tabs) == x.id)[0].documentType
        }});
        console.log("this.uploadMediaDialoagAssetInput",this.uploadMediaDialoagAssetInput);
        this.uploadMediaDialogSelectedFileIndex = 0;
        this.showFileUploadModal = true
      },
      isValidFileType(filename) {
        return this.isDocument(filename) || (filename.split(".").pop() == "woff") || (filename.split(".").pop() == "ttf") || (filename.split(".").pop() == "otf");
      },
      isDocument(filename) {
        if(typeof filename != 'string' ) return false;
        return ['pdf', 'doc', 'docx', 'txt', 'xlsx', 'pptx','csv','xls'].includes(filename.split(".").pop());
      },
      isFont(filename) {
        if(typeof filename != 'string' ) return false;
        return ['woff', 'ttf', 'otf'].includes(filename.toLowerCase().split(".").pop());
      },
      formatFileSize(bytes) {
        return bytes < 500 
          ? bytes + ' bytes' 
          : bytes < 1024 * 500 
          ? (bytes / 1024).toFixed(2) + ' KB' 
          : bytes < 1024 * 1024 * 500 
          ? (bytes / (1024 * 1024)).toFixed(2) + ' MB' 
          : (bytes / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
      },
      tabClicked(value) {
        console.log("tabClicked",value);
        console.log("tabs",this.tabs);
        this.filterByTab();
      },
      tabToDocumentTypeId(tabNumber) {
        console.log("tabNumber",tabNumber);
        return this.tabLookupDocumentTypeId[tabNumber];
      },
      filterByTab() {
        this.tabFilteredCards = [];
        var documentType = this.tabToDocumentTypeId(this.tabs);
        console.log("documentType",documentType);
        this.tabFilteredCards = [...this.campaignAssets.filter((x)=>{
          return documentType == x.documentType.id
        })];
        console.log("this.tabFilteredCards",this.tabFilteredCards);
      },
      async removeAsset() {
        try {
          await CampaignController.deleteAsset(this.selectedAsset.id)
          .then(res => {console.log("res",res); this.$root.$emit("snackbarSuccess", res.data.message); this.getAssets();})
          .catch(error => {this.$root.$emit("snackbarError", error.response.data.message)})
        } catch(err) {
          console.log(err);
        }
      },
      getIconByFilename(filename) {
        var extension = filename.toLowerCase().split('.').pop();
        switch(extension) {
          case 'xls':
          case 'xlsx':
            return {icon:"mdi-microsoft-excel", color:"green"};
          case 'doc':
          case 'docx':
            return {icon:"mdi-microsoft-word", color:"blue"};
          case 'pptx':
            return {icon:"mdi-microsoft-powerpoint",color:"orange"};
          case 'pdf':
            return {icon:"mdi-file-pdf-box",color:"red"};
          case 'csv':
            return {icon:"mdi-file-delimited", color:"white"};
          case 'txt':
            return {icon:"mdi-text-box", color: "grey"}
          case 'ttf':
          case 'otf':
            return {icon:"mdi-format-size", color:"red"};
          case 'woff':
            return {icon:"mdi-format-size", color:"green"};
        }
        return {icon:"mdi-text-box", color:"white"};
      },
      decreaseIndex() {
        if (this.uploadMediaDialogSelectedFileIndex > 0) {
          this.uploadMediaDialogSelectedFileIndex--;
        }
      },
      increaseIndex() {
        if (this.uploadMediaDialogSelectedFileIndex < this.filesForUpload.length) {
          this.uploadMediaDialogSelectedFileIndex++;
        }
      },
      openAssetEdit(asset) {
        console.log("openAssetEdit",asset);
        this.selectedAsset = asset;
        this.showAssetUpdateModal = true;
      },
      async updateAsset() {
        console.log("updateAsset",this.selectedAsset);
        this.showAssetUpdateModal = false;
        /*
            Id: 1, // The CampaignAssetID (not the mediaId)
    DocumentTypeId: 1, // The ID as per the documentTypes endpoint
    Description: "This is an edited note" 
        */
        try {
          if(this.selectedAsset.promoted && this.selectedAsset.documentType.documentType != "Media Plans") {
            console.log("asset is unpromoted")
            var res = await CampaignController.promoteAsset({"campaignAssetId":this.selectedAsset.id,"promote":false});
            console.log("clickPromated > res",res);
          }
          await CampaignController.editAsset(
            {
              id: this.selectedAsset.id, 
              documentTypeId: this.documentTypes.filter((x)=>x.documentType == this.selectedAsset.documentType.documentType)[0].id,
              description: this.selectedAsset.description
            })
          .then(res => {console.log("res",res); this.$root.$emit("snackbarSuccess", res.data.message);})
          .catch(error => {this.$root.$emit("snackbarError", error.response.data.message)})
        } catch(err) {
          console.log(err);
        }
        this.getAssets();
      },
      async clickPromated(asset) {
        console.log("clickPromated > asset: ",asset);
        // Send Promoted change to the server
        try {
          var res = await CampaignController.promoteAsset({"campaignAssetId":asset.id,"promote":!asset.promoted});
          console.log("clickPromated > res",res);
          this.$root.$emit("snackbarSuccess", "Asset promotion updated");
          if(!asset.promoted) {
            console.log("clickPromated > asset: ",asset)
//            this.campaignAssets.forEach(async x=>{
            for(var i = 0; i < this.campaignAssets.length; i++) {
              let x = this.campaignAssets[i]; 
              if(x.id != asset.id && x.documentType.documentType == "Media Plans" && x.promoted) {
                var res = await CampaignController.promoteAsset({"campaignAssetId":x.id,"promote":false});
                console.log("clickPromated > res",res);
                this.$root.$emit("snackbarSuccess", "Asset promotion updated");
              } 
            }
          }
          this.getAssets();
        } catch(err) {
          console.log("Error: ",err);
          this.$root.$emit('snackbarError', 'Asset Promotion Error');
        }
      },
      async downloadFile(asset) {
        try {
          const res = await CampaignController.downloadMedia(asset.media.id);
          console.log("res",res);
          if(res.status != 200) {
            this.$root.$emit("snackbarError", "Network Error!")
            return;
          }
          
          const urlObject = window.URL.createObjectURL(res.data);
          const a = document.createElement('a');
          a.href = urlObject;
          a.download = asset.media.originalFileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(urlObject);
          this.$root.$emit("snackbarSuccess", "Download Success!");
        } catch(err) {
          console.log(err);
          this.$root.$emit("snackbarError", err.response.data.message)
        }
      }
    },
  }
</script>

<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>